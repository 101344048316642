<template>
    <div>
        <label class="button" for="loadJson">Uploader un lottie json</label>
        <input id="loadJson" type="file" @change="sendJsonForAnalyse" hidden/>
        <div v-if="fontFamilyFound.length > 0">
            Font Family Founded : <br/>
            <div v-for="font in fontFamilyFound" :key="font">
                <a>{{font.font_fName}}</a>
                <p class="button" @click="saveFont(font)">Save in database</p>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'



export default {
  data () {
    return {
        fontFamilyFound : []
    }
  },
  methods: {
      sendJsonForAnalyse(evt){
            let data = new FormData();
            data.append('file', evt.target.files[0]);
            axios.post("animations/font/analyse", data, { contentType: 'multipart/form-data'})
            .then(res =>{
                console.debug(res.data)
                this.fontFamilyFound = res.data
            })
            .catch(e =>{
                console.error(e)
            })
      },
      saveFont(font){
          console.log(font)
          axios.post("animations/font", font)
          .then(res =>{
                console.debug(res.data)
                this.Toaster.success(this.$t('Toaster.success.add'))
            })
            .catch(e =>{
                console.error(e)
            })
      }
  },
  
    
}
</script>